import React from "react";
import "./Services.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iphone from "../../Images/iPhone-6S.jpg";
import android from "../../Images/Glaxy-S7.jpg";
import windowsPhone from "../../Images/Lumia-950.jpg";
import phpImg from "../../Images/PHP (1).png";
import wordPress from "../../Images/Wordpress-01.png";
import nodeJs from "../../Images/nodejs-logo.png";
import msLoge from "../../Images/Microsoft.png";

import {
  faPersonDigging,
  faClapperboard,
  faCompassDrafting,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {

  let kudos = [
    {
      id: 1,
      icon: faCompassDrafting,
      name: "Design Creatively",
      text: "All application development begins with a concept and design. Inviting us into the process early allows our team to learn your business needs and translate that into world class technology. We’ll ask tough questions and make sure the data structure is sound and complete. Any API’s must be vetted. We’ll find holes before spending on development…if there are any.",
    },
    {
      id: 2,
      icon: faPersonDigging,
      name: "Build Efficiently",
      text: "Our experienced in-house staff is skilled in multiple disciplines with decades of knowledge in a wide range of information and communications technology. This phase of keystroking is not a commodity. You need the skill to get it done right and the commitment to get it done fast. Version 1.0 should knock their socks off. Our rigorous testing and documentation ensure it.",
    },
    {
      id: 3,
      icon: faClapperboard,
      name: "Action Time",
      text: "The project shipment phase is commonly misunderstood by other app firms and especially so by the client. Launching your app across several platforms and stores is usually the most critical and sensitive step in the go-to-market process. We manager this process meticulously and make sure you just see it being downloaded by excited new users like movie magic.",
    },
  ];

  const mobilePlatform = [
    {
      id: 1,
      img: iphone,
      name: "Apple iOS",
      text: "As of today, iPhone apps make 50% of the total revenue generated by the mobile application marketplace, according to a recent survey. We have been contracted to develop highly interactive social and business apps for a diverse global customer list. Our proficient team of iPhone app developers has an in-depth understanding and knowledge of the iOS platform and seven years’ intensive experience in building customized iOS apps to compliment your business needs. From business apps to games, our iPhone and iPad app developers understand how to bring your ideas to life in the form of a rich iOS app backed by a robust back-end system.",
      services: [
        "Location services",
        "Social Network Integration",
        "In-App Purchases",
        "Push Notifications",
        "Externe APIs",
        "Retina Graphics",
      ],
      icon: faChevronCircleRight,
    },
    {
      id: 2,
      img: android,
      name: "Google Android",
      text: "Our expert team of Android application developers is your most reliable partner in providing customized Android application development services. We take that extra step to fully understand the needs of our clients and their target audience – we then create awesome products that will be loved by both. Over the past seven years, our Android app developers have gained deep insight into Android application development.",
      services: [
        "Android M-commerce Applications",
        "Android Widget Development",
        "Android Social Media Applications",
        "Android Support & Maintenance",
        "Android Apps Testing / Portability",
      ],
      icon: faChevronCircleRight,
    },
    {
      id: 3,
      img: windowsPhone,
      name: "Microsoft Windows Phone",
      text: "Since the release of Windows 8, Windows app development has become crucial to businesses in ways previously dominated by Android and Apple.. An increasing number of smart phone users are shifting to Windows owing to its user-friendly features and above all the Windows integration which help you take your PC almost everywhere with you in the form of a Windows smart phone or tablet. In no time at all, Windows 8 has become a favorite of both individuals and business across the world, making it important for companies to have their presence on Windows platform.",
      services: [
        "Seamless data connectivity",
        "Rich API support",
        "Enhanced security",
        "Pocket Outlook Object Model (POOM)",
        "Multithreading",
      ],
      icon: faChevronCircleRight,
    },
  ];

  const webPlatform = [
    {
      id: 1,
      img: phpImg,
      name: "PHP and web technologies are staples",
      text: "With the improvement in technology and a surge in the number of users shifting to mobiles and tablets, there’s a dire need to optimize the multi-device experience for your customers. To keep up with this growing trend, an increasing number of industries need professional web development solutions including e-commerce solutions, Portals & CMS customization and application integration.",
      services: [
        "Languages: C, C++, C#, PHP, Ruby",
        "Frameworks: CakePHP, ASP. Net, Zend",
        "CMS: Joomla, WordPress, Drupal",
        "Databases: MySQL, Oracle, SQL Server",
        "E-Commerce: Magento, Shopify, Opencart",
        "Integration: Wowza, Oracle, Salesforce.com, SAP, Windows Azure",
      ],
      icon: faChevronCircleRight,
    },
    {
      id: 2,
      img: wordPress,
      name: "WordPress & Co.",
      text: "Starting as simple tools for bloggers, WordPress etc. have fully evolved into CMS, shop and portal systems of today forming the basis of millions of large and small sites and landing pages. The ability create responsive, mobile optimized and creative websites in short time, enables designers, marketers and content writers to work and publish directly without developer intervention. We, as developers, offers success by extending and customizing these frameworks through plugins, themes and custom PHP code to meet your exact integrations and requirements.",
      services: [
        "CMS: WordPress, Drupal, Joomla, Typo3, eZSystem",
        "E-Commerce: WooCommerce, XTCommerce, Magento, Shopify, Opencart",
        "Integration: Analytics, DirectMailing, Blogs",
      ],
      icon: faChevronCircleRight,
    },
    {
      id: 3,
      img: nodeJs,
      name: "Node.js, AngularJS und JavaScript Farmeworks",
      text: "Slowly taking over a commendable place in universal web development JS frameworks like Node.js, Meteor and many more, offer a good opportunity for teams to reuse their front-end programming skills on the server side. The set of libraries and community has grown now for the enterprise and serious web products to consider JavaScript as a primary development platform",
      services: [],
      icon: {},
    },
    {
      id: 4,
      img: msLoge,
      name: "Microsoft ASP.net",
      text: "Our team of .Net developers has nine years of experience of developing cutting-edge web applications. Microsoft .Net Development experts at Exofters Pvt Ltd. possess in-depth knowledge of their domain and stay updated with technological progression associated with ASP. Net. We are adept in using leading Microsoft technologies to develop applications that are tailored to meet the needs of your business across various industry segments.",
      services: [
        "ASP .NET 1.1, 2.0, 4.0",
        "Microsoft Silverlight 1, 2, 3, 4, 5",
        "Microsoft Dynamics CRM 4 und 2011 Integration",
        "Microsoft SQL Server 2005, 2008",
        "Microsoft .Net Framework 1.0, 2.0, 3.0, 3.5, 4.0",
      ],
      icon: faChevronCircleRight,
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div className="services-hero-section">
        <p className="services-main-text1">
          The mobile tidle wave is only getting larger
        </p>
        <p className="services-main-text2">
          Now you are bringing a fantastic new idea to life. We'll put it in
          user's hands.
        </p>
        <p className="services-main-text3">
          Time and money is used to keep newcomers out of the market.
        </p>
        <p className="main-text4">
          We beleive there is room for more entrants and we will level the
          playing field for you.
        </p>
        <Button href="/contact" className="services-start-btn">
          Build my app now!
        </Button>
      </div>

      {/* Service Header Section */}
      <div className="container pt-5">
        <div className="row">
          {kudos.map((item) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-6 pt-3" key={item.id}>
                <div className=" kudos-card">
                  <FontAwesomeIcon className="kudos-icon" icon={item.icon} />
                  <p className="kudos-name pt-4">{item.name}</p>
                </div>
                <p className="kudos-text px-md-3 px-sm-3 px-3">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Mobile Platforms Section */}

      <div className="container py-5">
        <h3 className="mob-plat-heading text-center pt-5">
          Our Mobile Platforms
        </h3>
        <p className="pt-5 px-md-3 px-sm-3 px-3" style={{ color: "#6a6d7c" }}>
          We stay up to date on all the changes and new technologies for major
          mobile app platforms. You worry about your core business objectives,
          we’ll take the technology from here.
        </p>

        <div className="row plat-row1 pt-5">
          {mobilePlatform.map((item) => {
            if (item.id % 2 === 0) {
              return (
                <>
                  <div className="col-lg-8 pt-5 details-col">
                    <h5 className="text-center f-dm">{item.name}</h5>
                    <p className="plat-text f-dm">{item.text}</p>
                    <div>
                      {item.services.map((service) => {
                        return (
                          <>
                            <div className="d-flex">
                              <div className="py-2">
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  className="chevron"
                                />
                              </div>
                              &nbsp; &nbsp;
                              <div className="py-2 serv-text f-dm">
                                {service}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-lg-4 pt-5 text-md-center img-col px-5">
                    <img className="plat-img" src={item.img} alt="" />
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="col-lg-4 text-md-center pt-5 img-col">
                    <img className="plat-img" src={item.img} alt="" />
                  </div>
                  <div className="col-lg-8 pt-5 details-col">
                    <h5 className="text-center f-dm">{item.name}</h5>
                    <p className="plat-text f-dm">{item.text}</p>
                    <div>
                      {item.services.map((service) => {
                        return (
                          <>
                            <div className="d-flex">
                              <div className="py-2">
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  className="chevron"
                                />
                              </div>
                              &nbsp; &nbsp;
                              <div className="py-2 serv-text f-dm">
                                {service}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
        <div className="row plat-row2">
          {mobilePlatform.map((item) => {
            return (
              <>
                <div className="col-lg-4 text-md-center text-sm-center text-center pt-5 img-col">
                  <img className="plat-img" src={item.img} alt="" />
                </div>
                <div className="col-lg-8 pt-5 details-col">
                  <h5 className="text-center f-dm">{item.name}</h5>
                  <p className="plat-text px-md-3 px-sm-3 px-3 f-dm">
                    {item.text}
                  </p>
                  <div>
                    {item.services.map((service) => {
                      return (
                        <>
                          <div className="d-flex px-md-3 px-sm-3 px-3">
                            <div className="py-2">
                              <FontAwesomeIcon
                                icon={item.icon}
                                className="chevron"
                              />
                            </div>
                            &nbsp; &nbsp;
                            <div className="py-2 serv-text f-dm">{service}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      {/* Web Platforms Section */}

      <div className="container py-5">
        <h3 className="mob-plat-heading text-center">Our Web Platforms</h3>
        <p
          className="pt-5 px-md-3 px-sm-3 px-3 f-dm"
          style={{ color: "#6a6d7c" }}
        >
          We stay up to date on all the changes and new technologies for major
          mobile app platforms. You worry about your core business objectives,
          we’ll take the technology from here.
        </p>

        <div className="row web-plat-row1 pt-5">
          {webPlatform.map((item) => {
            if (item.id % 2 === 0) {
              return (
                <>
                  <div className="col-lg-8 pt-5 details-col">
                    <h5 className="text-center f-dm">{item.name}</h5>
                    <p className="plat-text f-dm">{item.text}</p>
                    <div>
                      {item.services.map((service) => {
                        return (
                          <>
                            <div className="d-flex">
                              <div className="py-2">
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  className="chevron"
                                />
                              </div>
                              &nbsp; &nbsp;
                              <div className="py-2 serv-text f-dm">
                                {service}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-lg-4 pt-5 text-md-center img-col px-5">
                    <img className="web-plat-img" src={item.img} alt="" />
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="col-lg-4 d-flex justify-content-center align-items-center text-md-center pt-5 img-col">
                    <img className="web-plat-img" src={item.img} alt="" />
                  </div>
                  <div className="col-lg-8 d-flex flex-column justify-content-center pt-5 details-col">
                    <h5 className="text-center f-dm">{item.name}</h5>
                    <p className="plat-text f-dm">{item.text}</p>
                    <div>
                      {item.services.map((service) => {
                        return (
                          <>
                            <div className="d-flex">
                              <div className="py-2">
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  className="chevron"
                                />
                              </div>
                              &nbsp; &nbsp;
                              <div className="py-2 serv-text f-dm">
                                {service}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
        <div className="row web-plat-row2">
          {webPlatform.map((item) => {
            return (
              <>
                <div className="col-lg-4 text-md-center text-sm-center text-center pt-5 img-col">
                  <img className="web-plat-img" src={item.img} alt="" />
                </div>
                <div className="col-lg-8 pt-5 details-col">
                  <h5 className="text-center">{item.name}</h5>
                  <p className="plat-text px-md-3 px-sm-3 px-3">{item.text}</p>
                  <div>
                    {item.services.map((service) => {
                      return (
                        <>
                          <div className="d-flex px-md-3 px-sm-3 px-3">
                            <div className="py-2">
                              <FontAwesomeIcon
                                icon={item.icon}
                                className="chevron"
                              />
                            </div>
                            &nbsp; &nbsp;
                            <div className="py-2 serv-text">{service}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
