const Images = {
  html5: require("./Images/html5.jpg"),
  css3: require("./Images/css3.png"),
  js: require("./Images/js.png"),
  ts: require("./Images/ts.png"),
  angular: require("./Images/angular.png"),
  react: require("./Images/react.png"),
  reactNative: require("./Images/react-native.png"),
  vue: require("./Images/vue.png"),
  flutter: require("./Images/flutter.png"),
  ionic: require("./Images/ionic.png"),
  laravel: require("./Images/laravel.png"),
  codeignitor: require("./Images/codeignitor.png"),
  iosandroid: require("./Images/iosandroid.png"),
  nodejs: require("./Images/nodejs.png"),
  mongodb: require("./Images/mongodb.png"),
  cordova: require("./Images/cordova.png"),
  capacitor: require("./Images/capacitor.png"),
  electron: require("./Images/electron.png"),
  jquerry: require("./Images/jquerry.png"),
  php: require("./Images/php.png"),
  aws: require("./Images/aws.png"),
  swagger: require("./Images/swagger.png"),
  postman: require("./Images/postman.png"),
  azure: require("./Images/azure.png"),
  digitalocean: require("./Images/digitalocean.png"),
  ecommerce: require("./Images/ecommerce.png"),
  blockchain: require("./Images/blockchain.png"),
  api: require("./Images/api.png"),
  firebase: require("./Images/firebase.png"),
  etraffic: require("./Images/etraffic.jpg"),
  feba: require("./Images/feba.jpg"),
  guest: require("./Images/guest.jpg"),
  ivory: require("./Images/ivory.jpg"),
  iwish: require("./Images/iwish.jpg"),
  ownersinfo: require("./Images/ownersinfo.jpg"),
  penthouse: require("./Images/penthouse.jpg"),
  quranradio: require("./Images/quraanradio.jpg"),
  xliquidus: require("./Images/xliquidus.jpg"),
};

export default Images;
